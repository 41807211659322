import {
  ButtonPrimary,
  ButtonSecondary,
  FlexRow,
  Heading2,
  Para,
  Stack,
  Form,
  FormFieldText,
  Formik
} from '@papertrail/styleguide'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as api from './api'
import { useTitle } from '@papertrail/web3-utils'

type FormErrors = {
  email?: string
}

enum RequestState {
  Initial,
  Loading,
  Loaded,
  Error
}

export default function ForgotPassword() {
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const email = params.get('email')

  useTitle('Reset Password')

  useEffect(() => {
    if (email) {
      setEmailToReset(email)
    }
  }, [email])

  function formikValidate(values) {
    const errors: FormErrors = {}

    if (!values.email) {
      errors.email = 'Email required.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(values.email)) {
      errors.email = 'Invalid email address.'
    }

    return errors
  }

  const [emailResetRequestState, setEmailResetRequestState] = useState(RequestState.Initial)
  const [emailToReset, setEmailToReset] = useState('')

  async function submit(email: string) {
    setEmailResetRequestState(RequestState.Loading)
    api.postResetPassword(email)
    setEmailToReset(email)
    setEmailResetRequestState(RequestState.Loaded)
  }

  function tryAgain() {
    setEmailResetRequestState(RequestState.Initial)
  }

  function gotoSignup() {
    history.push('/signup')
  }

  return (
    <Stack p={2} alignSelf={'stretch'}>
      <FlexRow justifyContent={'flex-start'}>
        <Link to={'/login'}>Back</Link>
      </FlexRow>
      {emailResetRequestState === RequestState.Initial && (
        <Stack alignItems={'center'} spacing={2} paddingTop={4}>
          <Heading2>Reset your password</Heading2>
          <Formik
            initialValues={{
              email: email ? email : ''
            }}
            validate={formikValidate}
            onSubmit={(values) => {
              submit(values.email)
            }}>
            <Form>
              <Stack spacing={2} alignItems={'center'} width={'350px'}>
                <Para align={'center'}>Enter your email below to receive instructions on resetting your password.</Para>
                <FormFieldText label="Email" placeholder="Enter email" name="email" mandatory />
                <ButtonPrimary isSubmit fixedWidth>
                  Send address reset link
                </ButtonPrimary>
                <p>Do not have an account?</p>
                <ButtonSecondary onClick={() => gotoSignup()}>Create your account</ButtonSecondary>
              </Stack>
            </Form>
          </Formik>
        </Stack>
      )}

      {emailResetRequestState === RequestState.Loaded && (
        <Stack alignItems={'center'} spacing={2} paddingTop={4}>
          <Heading2>Check your inbox</Heading2>
          <Stack spacing={2} alignItems={'center'} width={'350px'}>
            <Para align={'center'}>
              <span>
                We have just emailed you instructions on resetting your password to <b>{emailToReset}</b>.
              </span>
            </Para>
            <Para align={'center'}>
              No email? Please check out our{' '}
              <a href="https://help.papertrail.io/en/articles/3629589-how-to-reset-your-password">
                resetting your password
              </a>{' '}
              help document for common reasons, or <a onClick={tryAgain}>try again</a> with a different email address.
            </Para>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
